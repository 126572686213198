/* eslint-disable */
require("script-loader!file-saver");
import { map, split, uniq, get, forEach } from "lodash";
import { empty, flattenObject, insertJs } from "@/utils";

function doInit(cb) {
  insertJs(
    "script",
    ["/external/xlsx/shim.min.js", "/external/xlsx/xlsx.full.min.js"],
    cb,
  );
}
function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts = {}) {
  var ws = {};
  var range = {
    s: {
      c: 10000000,
      r: 10000000,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  const color = "bc2b87";
  const tintColor = tint => {
    let red = parseInt(color.slice(0, 2), 16);
    let green = parseInt(color.slice(2, 4), 16);
    let blue = parseInt(color.slice(4, 6), 16);

    if (tint === 0) {
      // when primary color is in its rgb space
      return color;
    } else {
      red += Math.round(tint * (255 - red));
      green += Math.round(tint * (255 - green));
      blue += Math.round(tint * (255 - blue));

      red = red.toString(16);
      green = green.toString(16);
      blue = blue.toString(16);

      return `${red}${green}${blue}`;
    }
  };
  const borderStyle = {
    style: "thin",
    color: {
      rgb: tintColor(0),
    },
  };
  for (var R = 0; R != data.length; ++R) {
    for (var C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = {
        v: data[R][C],
      };
      if (cell.v == null) continue;
      var cell_ref = XLSX.utils.encode_cell({
        c: C,
        r: R,
      });

      if (typeof cell.v === "number") cell.t = "n";
      else if (typeof cell.v === "boolean") cell.t = "b";
      else if (cell.v instanceof Date) {
        cell.t = "n";
        cell.z = XLSX.SSF._table[14];
        cell.v = datenum(cell.v);
      } else cell.t = "s";
      cell.s = {
        font: {
          sz: 14,
          name: "Times New Roman",
          color: {
            rgb: "000000",
          },
        },
      };
      if (!empty(opts.numOfHeader) && R < opts.numOfHeader) {
        cell.s = {
          ...cell.s,
          font: {
            ...cell.s.font,
            bold: true,
            color: {
              rgb: tintColor(0),
            },
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          fill: {
            fgColor: {
              rgb: tintColor(0.7),
            },
          },
          border: {
            right: borderStyle,
            left: borderStyle,
            top: borderStyle,
            bottom: borderStyle,
          },
        };
      }

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws["!ref"] = XLSX.utils.encode_range(range);
  return ws;
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function swap(input, index_A, index_B) {
  var temp = input[index_A];

  input[index_A] = input[index_B];
  input[index_B] = temp;
}

function getMergeCell(val, arr) {
  var startInd = arr.indexOf(val),
    endInd = arr.indexOf(val);

  for (var i = startInd + 1; i < arr.length; i++) {
    if (arr[i] === val) {
      endInd++;
    } else {
      break;
    }
  }
  if (startInd === endInd) return null;
  return { s: startInd, e: endInd };
}

export function exportJsonToExcel({
  priority = [],
  data,
  filename,
  autoWidth = true,
  bookType = "xlsx",
} = {}) {
  doInit(() => {
    /* original data */
    filename = filename || "excel-list";

    /** Get key of value  */
    let headerKey = [],
      merges = [];
    data.forEach(item => {
      const tmp = flattenObject(item);
      headerKey = [...headerKey, ...tmp];
    });

    headerKey = uniq(
      headerKey.sort((i, j) => i.split(".").length > j.split(".").length),
    );
    if (!empty(priority)) {
      priority.forEach((key, ind) =>
        swap(headerKey, ind, headerKey.indexOf(key)),
      );
    }
    let max = 0;
    const headerArr = map(headerKey, item => {
      const tmp = split(item, ".");
      if (max < tmp.length) {
        max = tmp.length;
      }
      return tmp;
    });
    const header = [];

    if (max > 0) {
      for (let i = 0; i < max; i++) {
        header.push(
          map(headerArr, header => (empty(header[i]) ? "" : header[i])),
        );
      }
    }
    headerKey.forEach((item, ind) => {
      const start = (item.match(/\./g) || []).length;
      let end = max - split(item, ".").length;
      if (start <= end) {
        if (end === start) {
          end += start;
        }
        merges.push({
          s: { c: ind, r: start },
          e: { c: ind, r: end },
        });
      }
    });

    const dataRaw = map(data, item =>
      map(headerKey, key => (empty(get(item, key)) ? "" : get(item, key))),
    );
    for (let i = header.length - 1; i > -1; i--) {
      dataRaw.unshift(header[i]);
    }
    let ws_name = "data";
    let wb = new Workbook(),
      ws = sheet_from_array_of_arrays(dataRaw, { numOfHeader: max }),
      headerNoDup = map(header, head =>
        head.filter(function (item, pos, self) {
          return !empty(item) && self.indexOf(item) === pos;
        }),
      );
    if (header.length > 1) {
      headerNoDup.forEach((headerArr, ind) => {
        headerArr.forEach(head => {
          const tmp = getMergeCell(head, header[ind]);
          if (!empty(tmp)) {
            merges.push({
              s: { c: tmp.s, r: ind },
              e: { c: tmp.e, r: ind },
            });
          }
        });
      });

      ws["!merges"] = merges;
      // ws['!freeze'] = 'A' + (max + 1)
    }
    ws["!freeze"] = {
      xSplit: 0,
      ySplit: max,
      topLeftCell: "A" + (max + 1),
      activePane: "bottomRight",
      state: "frozen",
    };
    if (autoWidth) {
      /*设置worksheet每列的最大宽度*/
      const colWidth = dataRaw.map(row =>
        row.map(val => {
          /*先判断是否为null/undefined*/
          let wch = 10;
          if (val == null) {
            wch = 10;
          } else if (val.toString().charCodeAt(0) > 255) {
            /*再判断是否为中文*/
            wch = val.toString().length * 2;
          } else {
            wch = parseInt(val.toString().length * 1.3) + 1;
          }
          return {
            wch,
          };
        }),
      );
      /*以第一行为初始值*/
      let result = colWidth[0];
      for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
          if (result[j]["wch"] < colWidth[i][j]["wch"]) {
            result[j]["wch"] = colWidth[i][j]["wch"];
          }
        }
      }
      ws["!cols"] = result;
    }
    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    var wbout = XLSX.write(wb, {
      cellStyles: true,
      bookType: bookType,
      bookSST: false,
      type: "binary",
    });
    saveAs(
      new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      }),
      `${filename}.${bookType}`,
    );
  });
}

export function exportJsonToExcelMultiSheet({
  priority = [],
  data,
  filename,
  autoWidth = true,
  bookType = "xlsx",
} = {}) {
  doInit(() => {
    /* original data */
    filename = filename || "excel-list";
    const wb = new Workbook();
    forEach(data, (dataInside, wsName) => {
      /** Get key of value  */
      let headerKey = [],
        merges = [];
      dataInside.forEach(item => {
        const tmp = flattenObject(item);
        headerKey = [...headerKey, ...tmp];
      });

      headerKey = uniq(headerKey.sort());

      if (!empty(priority)) {
        priority.forEach((key, ind) =>
          swap(headerKey, ind, headerKey.indexOf(key)),
        );
      }
      let max = 0;
      const headerArr = map(headerKey, item => {
        const tmp = split(item, ".");
        if (max < tmp.length) {
          max = tmp.length;
        }
        return tmp;
      });

      const header = [];

      if (max > 0) {
        for (let i = 0; i < max; i++) {
          header.push(
            map(headerArr, header => (empty(header[i]) ? "" : header[i])),
          );
        }
      }
      headerKey.forEach((item, ind) => {
        const start = (item.match(/\./g) || []).length;
        let end = max - split(item, ".").length;
        if (start <= end) {
          if (end === start) {
            end += start;
          }
          merges.push({
            s: { c: ind, r: start },
            e: { c: ind, r: end },
          });
        }
      });

      const dataRaw = map(dataInside, item =>
        map(headerKey, key => (empty(get(item, key)) ? "" : get(item, key))),
      );
      for (let i = header.length - 1; i > -1; i--) {
        dataRaw.unshift(header[i]);
      }
      let ws_name = wsName;
      let ws = sheet_from_array_of_arrays(dataRaw, { numOfHeader: max }),
        headerNoDup = map(header, head =>
          head.filter(function (item, pos, self) {
            return !empty(item) && self.indexOf(item) === pos;
          }),
        );
      if (header.length > 1) {
        headerNoDup.forEach((headerArr, ind) => {
          headerArr.forEach(head => {
            const tmp = getMergeCell(head, header[ind]);
            if (!empty(tmp)) {
              merges.push({
                s: { c: tmp.s, r: ind },
                e: { c: tmp.e, r: ind },
              });
            }
          });
        });

        ws["!merges"] = merges;
        // ws['!freeze'] = 'A' + (max + 1)
      }
      ws["!freeze"] = {
        xSplit: 0,
        ySplit: max,
        topLeftCell: "A" + (max + 1),
        activePane: "bottomRight",
        state: "frozen",
      };
      if (autoWidth) {
        /*设置worksheet每列的最大宽度*/
        const colWidth = dataRaw.map(row =>
          row.map(val => {
            /*先判断是否为null/undefined*/
            let wch = 10;
            if (val == null) {
              wch = 10;
            } else if (val.toString().charCodeAt(0) > 255) {
              /*再判断是否为中文*/
              wch = val.toString().length * 2;
            } else {
              wch = parseInt(val.toString().length * 1.3) + 1;
            }
            return {
              wch,
            };
          }),
        );
        /*以第一行为初始值*/
        let result = colWidth[0];
        for (let i = 1; i < colWidth.length; i++) {
          for (let j = 0; j < colWidth[i].length; j++) {
            if (result[j]["wch"] < colWidth[i][j]["wch"]) {
              result[j]["wch"] = colWidth[i][j]["wch"];
            }
          }
        }
        ws["!cols"] = result;
      }
      /* add worksheet to workbook */
      wb.SheetNames.push(ws_name);
      wb.Sheets[ws_name] = ws;
    });

    var wbout = XLSX.write(wb, {
      cellStyles: true,
      bookType: bookType,
      bookSST: false,
      type: "binary",
    });
    saveAs(
      new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      }),
      `${filename}.${bookType}`,
    );
  });
}
